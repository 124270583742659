'use client'

import { useCallback, type DetailedHTMLProps, type HTMLAttributes, type MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import { useRouter } from 'src/hooks/useRouter'

export interface LinkProps
  extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'onClick'> {
  to: string
  onClick?: () => void | Promise<void>
  action?: string
  disabled?: boolean
  hash?: string
  multiAction?: string
  newTab?: boolean
  stopPropagation?: boolean
  doNotTrack?: boolean
  synthetic?: boolean
  tracking?: object
}

export const Link = ({
  action = obe.events.user_link,
  children,
  className,
  disabled = false,
  hash,
  multiAction,
  newTab = false,
  onClick,
  stopPropagation = false,
  doNotTrack = false,
  synthetic = true,
  to,
  tracking = {},
  ...props
}: LinkProps) => {
  const router = useRouter()

  const handleClick = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      if (stopPropagation) event.stopPropagation()

      event.preventDefault()
      if (disabled) return

      await onClick?.()

      if (multiAction && !doNotTrack) {
        obe.analytics.track(multiAction, {
          from: window.location.pathname,
          ...tracking,
          to,
        })
      }

      !doNotTrack &&
        obe.analytics.track(action, {
          from: window.location.pathname,
          ...tracking,
          to,
        })

      if (newTab || to?.startsWith('http') || event.metaKey) {
        window.open(to, '_blank')
      } else if (synthetic) {
        setTimeout(() => {
          router.push(to)
        }, 100)
      } else if (hash) {
        if (to === window.location.pathname) {
          document.getElementById(hash)?.scrollIntoView()
          window.location.hash = `#${hash}`
        } else {
          window.location.href = `${to}#${hash}`
        }
      } else {
        window.location.href = to
      }
    },
    [
      action,
      disabled,
      doNotTrack,
      hash,
      multiAction,
      newTab,
      onClick,
      router,
      stopPropagation,
      synthetic,
      to,
      tracking,
    ],
  )

  return (
    <a
      href={to}
      onClick={handleClick}
      rel={newTab ? 'noreferrer noopener' : undefined}
      target={newTab ? '_blank' : undefined}
      className={twMerge('text-near-black hover:text-near-black', className)}
      {...props}
    >
      {children}
    </a>
  )
}
