import { isProd, isStaging } from 'src/utils/systemUtils'

const cloudflarePrefix = 'cdn-cgi/image/'
const cloudflarePrefixWithDomain = 'https://obefitness.com/cdn-cgi/image/'

interface CloudflareLoaderParams {
  src: string
  width: number | undefined
  density?: number
}

export const cloudflareLoader = ({ src, width, density }: CloudflareLoaderParams) => {
  const isAbsolute = src?.startsWith('/')
  const isCloudflarePrefixed = src?.includes(cloudflarePrefix)

  // skip for local assets when running in dev mode / using vercel preview
  if (isAbsolute && !isProd && !isStaging) {
    return src
  }

  // skip for other origins
  if (!isAbsolute && !isCloudflarePrefixed) {
    return src
  }

  const prefixedUrl = isAbsolute
    ? `${cloudflarePrefixWithDomain}/http://${isProd ? '' : 'staging.'}obefitness.com${src}`
    : src

  const params = ['fit=contain', 'format=auto']

  if (width) {
    params.push(`width=${width}`)
  }

  const paramsString = params.join(',')

  const pixelDensity = density ? ` ${density}x` : ''

  return `${prefixedUrl.replace(
    cloudflarePrefix,
    `${cloudflarePrefix}${paramsString}`,
  )}${pixelDensity}`
}

export const widths = {
  xsmall: 50,
  avatar: 128,
  logo: 200,
  card: 400,
  mobile: 1000,
  desktop: 1200,
  hd: 1920,
  hires: 2900,
} as const

export const avatarLoader = (src: string) => cloudflareLoader({ src, width: widths.avatar })
export const xSmallAvatarLoader = (src: string) => cloudflareLoader({ src, width: widths.avatar })

interface ThumbnailLoaderParams {
  src: string
  width?: number
}

export const jwThumbnailLoader = ({ src, width = 640 }: ThumbnailLoaderParams) => {
  const suffix = '-1920.jpg'
  const isThumbnail = src?.endsWith(suffix)

  if (!isThumbnail) {
    return src
  }

  return src?.replace(suffix, `-${width}.jpg`)
}
