'use client'

import { CSSProperties, memo, useState } from 'react'
import { cloudflareLoader } from 'src/utils/image'

interface ContainerOptions {
  circle?: boolean
  shadow?: boolean
}

interface Props extends ContainerOptions {
  alt?: string
  className?: string
  containerStyle?: CSSProperties
  fadeIn?: boolean
  fitToHeight?: boolean | string | number
  height?: number | 'auto'
  src?: string
  width?: number
  cdnWidth?: number
  cdnWidth2x?: number
}

export const Image = memo(
  ({
    alt = 'obé',
    className,
    containerStyle,
    fadeIn = false,
    fitToHeight,
    height = 1,
    src = '//:0',
    width = 1,
    cdnWidth,
    cdnWidth2x,
    circle,
    shadow,
    ...props
  }: Props) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [isError, setIsError] = useState(false)

    const box =
      typeof fitToHeight === 'number' && typeof height === 'number'
        ? {
            paddingTop: fitToHeight,
            width: (width / height) * fitToHeight,
          }
        : {
            paddingTop: height === 'auto' ? 0 : `${(height / width) * 100}%`,
            width: '100%',
          }

    return (
      <div className={className}>
        <Styles.Container
          circle={circle}
          shadow={shadow}
          style={{ ...box, ...(containerStyle || {}) }}
        >
          <Styles.Image
            alt={alt}
            isLoaded={!fadeIn || isLoaded}
            onLoad={() => fadeIn && setIsLoaded(true)}
            onError={() => setIsError(true)}
            src={isError ? '/favicon.png' : cloudflareLoader({ src, width: cdnWidth })}
            {...(cdnWidth2x
              ? {
                  srcSet: cloudflareLoader({ src, width: cdnWidth2x, density: 2 }),
                }
              : {})}
            style={{
              position: height === 'auto' ? 'relative' : 'absolute',
            }}
            {...props}
          />
        </Styles.Container>
      </div>
    )
  },
)

const Styles = {
  Container: styled.div<ContainerOptions>`
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    ${({ circle }) =>
      circle &&
      css`
        border-radius: 50%;
      `}

    ${({ shadow }) =>
      shadow &&
      css`
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      `}
  `,
  Image: styled.img<{ isLoaded: boolean }>`
    left: 0;
    max-width: 100%;
    top: 0;
    transition: opacity 0.2s ease-out;
    width: 100%;

    ${({ isLoaded }) => css`
      opacity: ${isLoaded ? 1 : 0};
    `}
  `,
}
